module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5QCTSV","includeInDevelopment":false,"timeout":500,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../../../node_modules/@phx-husky/gatsby-plugin-static-search/gatsby-browser.js'),
      options: {"plugins":[],"queries":[{"query":"{\n  allLds {\n    nodes {\n      id\n      body\n    }\n  }\n}","indexName":"dettol-au-lds"},{"query":"{\n  allUmbracoArticle {\n    nodes {\n      lang\n      id\n      link\n      title\n      intro\n      label\n      articleBody {\n        articleSectionDescription\n        authorName\n        quoteText\n        authorTitle\n      }\n      tags {\n        name\n      }\n    }\n  }\n}","indexName":"dettol-au-articles","settings":{"attributesForFaceting":["lang","tags","filterOnly(type)"],"searchableAttributes":["unordered(tags)","title","intro","unordered(articleDescriptions)","articleAuthorNames,articleAuthorTitles,articleQuoteTexts"]}},{"query":"{\n  allUmbracoProduct {\n    nodes {\n      lang\n      titleBrandbank\n      name\n      id\n      ean\n      link\n      sku\n      size\n      descriptionShort\n      descriptionLong\n      recognisableFeature\n      localHeroImage {\n        childImageSharp {\n          fluid(maxWidth: 300) {\n            aspectRatio\n            base64\n            originalImg\n            originalName\n            presentationHeight\n            presentationWidth\n            sizes\n            src\n            srcSet\n            srcSetWebp\n            srcWebp\n          }\n        }\n      }\n      tags {\n        name\n      }\n    }\n  }\n}","indexName":"dettol-au-products","settings":{"attributesForFaceting":["lang","tags","filterOnly(type)"],"searchableAttributes":["unordered(tags)","name","titleBrandbank","intro","recognisableFeature","descriptionShort","descriptionLong"]}}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":0},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dettol","short_name":"Dettol","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"/home/vsts/work/1/s/packages/gatsby-theme-dettol/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bd5901d4f48dc73b9219506e5c543eae"},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../gatsby-theme-dettol/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{}},
    }]
